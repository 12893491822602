.title-text-large {
  font-family: var(--font-wixmadefordisplay);
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  width: 332px;
}

.title-text-small {
  font-family: var(--font-wixmadefordisplay);
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  width: 162px;
}

@media (max-width: 1919px) {
  .title-text-large {
    font-size: 28px;
    line-height: 28px;
    width: 238px;
  }
}
@media (max-width: 1365px) {
  .title-text-large {
    font-size: 28px;
    line-height: 28px;
    width: 238px;
  }
}
@media (max-width: 1023px) {
  .title-text-large {
    font-size: 18px;
    line-height: 23px;
    width: 157px;
  }
}
@media (max-width: 768px) {
  .title-text-large {
    font-size: 12px;
    line-height: 15px;
    width: 102px;
  }
}
